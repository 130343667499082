import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { navigationPaths } from "../../constants/paths"
import { getSubdomain, getStyles } from "../shared/StylesheetInjector"
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons"
import HeaderLinks from "./HeaderLinks"
import { Dropdown, Menu, Header, Responsive, Checkbox, Image } from "semantic-ui-react"
import {
  CURRENT_ACCOUNT_ID,
  CURRENT_FLEET,
  CURRENT_FLEET_ID,
  FLEET,
  FMC
} from "../../constants/application"
import { isFmcPath } from "../../helpers/pathHelpers"
import { FMC_FLEET_ADMIN, FMC_FLEET_MANAGER } from "../../constants/roles"
import { isEqual } from "lodash"
import { CANDA_COUNTRY_CODE, EN_LOCALE, FR_LOCALE, UNDEFINED } from "../../constants/users"
import {
  isFleetAdvise,
  isFleetMaintenanceHubCanada,
  isFleetcor
} from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { setLanguage } from "../../helpers/translationHelpers"
import ManageAccount from "./ManageAccount"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import MembershipMenuLogo from "../../assets/images/membership-menu-logo.svg"
import HeaderMenu from "./HeaderMenu"
import { isEmpty } from "lodash"
import CompanyDropdownLogo from "../../assets/images/company-dropdown-arrow.svg"
import * as userHelpers from "../../helpers/userHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { FUELMAN_FAQ } from "../../constants/fleets"

const styles = getStyles()

class SiteHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: props.language === EN_LOCALE ? false : true,
      accountFleetsData: [],
      currentAccountId: +sessionHelpers.getApplicationCookie(CURRENT_ACCOUNT_ID),
      currentFleet: +sessionHelpers.getApplicationCookie(CURRENT_FLEET_ID)
    }
    this.handleUserAccount = this.handleUserAccount.bind(this)
    this.handleFleetChange = this.handleFleetChange.bind(this)
  }

  toggleButton = async () => {
    const { dispatch, currentUser } = this.props
    const language = this.state.toggle ? EN_LOCALE : FR_LOCALE
    this.setState((prevState) => ({ toggle: !prevState.toggle }))
    dispatch({ type: "SET_USER_LANGUAGE", payload: { language } })
    dispatch({ type: "UPDATE_USER_SESSION_DATA", payload: { language: language } })
    setLanguage(language, CANDA_COUNTRY_CODE)
    await dispatch({
      type: "USER_CREATE_OR_UPDATE_SAGA",
      payload: { ...currentUser, ...{ language } },
      callback: this.afterToggle
    })
  }

  afterToggle = () => {
    const { dispatch, match, vehicle } = this.props
    if (match.path === "/vehicles/:id") {
      dispatch({
        type: "NEXT_MAINTENANCE_INTERVAL_SAGA",
        payload: { vehicleIds: [vehicle.id] }
      })
    }
    if (match.path === "/orders/:id/approvals") {
      dispatch({
        type: "SHOP_ORDER_LOAD_PREVIEW",
        payload: {
          priceAsApprovalsLogic: true,
          shopOrderId: Number(match.params.id)
        }
      })
    }
    dispatch({
      type: "SERVICE_REQUEST_GET_ALL"
    })
  }

  static propTypes = {
    fleet: PropTypes.object.isRequired
  }

  shouldLoadFleetData() {
    const { fleet } = this.props
    return fleet.constructor === Object && Object.keys(fleet).length === 0
  }

  shouldLoadCurrentUserData() {
    const { currentUser } = this.props
    return currentUser.constructor === Object && Object.keys(currentUser).length === 0
  }

  componentDidMount() {
    const { currentUser = {}, fleet, dispatch, isFMC, language } = this.props
    if (this.shouldLoadFleetData()) {
      dispatch({
        type: "FLEET_LOAD_SAGA",
        payload: { fleetId: currentUser.fleet_id || currentUser.fleet_id }
      })
    }
    if (this.shouldLoadCurrentUserData()) {
      dispatch({
        type: "CURRENT_USER_LOAD_SAGA",
        payload: {}
      })
    }
    !!this.state.currentAccountId &&
      this.state.currentAccountId !== UNDEFINED &&
      isFleetcor() &&
      this.requestAccountFleets(false)
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentUser } = this.props
    if (!isEqual(prevProps.currentUser, currentUser)) {
      currentUser.language == EN_LOCALE
        ? this.setState({ toggle: false })
        : this.setState({ toggle: true })
    }

    if (
      prevState.currentAccountId !== this.state.currentAccountId &&
      !!this.state.currentAccountId &&
      this.state.currentAccountId !== UNDEFINED &&
      isFleetcor()
    ) {
      this.requestAccountFleets()
    }

    const prevAccountId =
      prevProps.fleet && prevProps.fleet.account ? prevProps.fleet.account.id : null
    const currentAccountId =
      this.props.fleet && this.props.fleet.account ? this.props.fleet.account.id : null

    if (prevAccountId !== currentAccountId && isFleetcor()) {
      this.setState({ currentAccountId: currentAccountId })
    }
  }

  requestAccountFleets(wantToChange = true) {
    this.props.dispatch({
      type: "ACCOUNT_FLEETS",
      payload: { accountId: +this.state.currentAccountId, setInRedux: false },
      callback: (status, data) => {
        this.afterAccountChangeRequest(status, data, wantToChange)
      }
    })
  }

  afterAccountChangeRequest(status, data, wantToChange) {
    const { accountFleets } = data
    this.setState({ accountFleetsData: accountFleets || [] })
    if (accountFleets && accountFleets.length > 1 && wantToChange) {
      this.handleFleetChange(accountFleets[0].id)
    }
  }

  flipFMC = () => {
    this.props.dispatch({
      type: "USERS_LOAD_SUCCEEDED",
      payload: {
        users: [],
        currentUserId: ""
      }
    })
    this.props.dispatch({
      type: "FMC_FLIP_SAGA",
      payload: { mode: FMC }
    })
  }

  isNotFleetAmerica = () => {
    const sub = getSubdomain()
    return sub != "fleetamerica"
  }

  clickChild = (e) => e.target.children[0] && e.target.children[0].click()

  handleFleetChange(fleet_id) {
    sessionStorage.removeItem(CURRENT_FLEET)
    this.setState({ currentFleet: fleet_id })
    this.props.dispatch({
      type: "FLEET_IMPERSONATION_SAGA",
      payload: { fleetId: fleet_id },
      callback: this.afterImpersonationRequest.bind(this)
    })
  }

  afterImpersonationRequest(status, data) {
    sessionHelpers.setApplicationCookie(CURRENT_ACCOUNT_ID, this.state.currentAccountId)
    sessionHelpers.setApplicationCookie(CURRENT_FLEET_ID, this.state.currentFleet)
    window.location.reload()
  }

  handleUserAccount(accountId) {
    this.setState({ currentAccountId: accountId })
  }

  render() {
    const { isFleetMode, t, account_fleets, currentUser, fleet, isFMC, isFMCMode } = this.props
    const { toggle, currentAccountId, accountFleetsData, currentFleet } = this.state

    const currentUserName = [currentUser.firstName, currentUser.lastName].filter((a) => a).join(" ")
    const shortened = currentUserName
      .split(" ")
      .map((s) => (s[0] ? s[0].toUpperCase() : null))
      .join("")
      .slice(0, 2)

    return (
      <Menu borderless size="massive" className="borderless-outline site-header">
        {isFleetMode &&
          fleet &&
          (accountFleetsData && accountFleetsData.length <= 1 ? (
            <Menu.Item className="fleet-label">{fleet.name}</Menu.Item>
          ) : (
            accountFleetsData.length > 1 && (
              <div className="fleet-label manage__account">
                <div className="segment__custom-class">
                  <Dropdown
                    className="fleetcoreAccount__select"
                    placeholder={t("common:selectFleet")}
                    inline
                    icon={<Image className="dropdown__logo" src={CompanyDropdownLogo} />}
                    options={userHelpers.selectAccountFleetOptions(
                      accountFleetsData.filter((f) => f.is_active === true && f.enrolled === true),
                      {
                        displayOtherID: true,
                        value: this.state.currentFleet
                      }
                    )}
                    value={this.state.currentFleet}
                    onChange={(event, { value }) =>
                      value && this.handleFleetChange(value, accountFleetsData)
                    }
                    valueForTitle={fleet.name}
                    search
                  />
                </div>
              </div>
            )
          ))}

        <Menu.Menu position="right">
          {isFleetMaintenanceHubCanada() && (
            <Menu.Item>
              EN &nbsp;
              <Checkbox toggle checked={toggle} onClick={this.toggleButton} />
              &nbsp; FR
            </Menu.Item>
          )}

          {isFMC && (isFleetMode || !isFMCMode) && (
            <Menu.Item>
              <Link
                style={{ color: styles.primaryColor }}
                to={navigationPaths.fmcDashboard()}
                onClick={this.flipFMC}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp; {t("backToFmcLabel")}
              </Link>
            </Menu.Item>
          )}

          {isFleetcor() && (
            <Menu.Item>
              <p className="faq" onClick={() => userHelpers.openWindow(FUELMAN_FAQ)}>
                {t("common:faq")}
              </p>
            </Menu.Item>
          )}

          {this.isNotFleetAmerica() && (
            <Menu.Item>
              <Link to={navigationPaths.membershipCard()}>
                <HeaderMenu logo={MembershipMenuLogo} label={t("membershipCard")} />
              </Link>
            </Menu.Item>
          )}

          {isFleetcor() && !isFMC && currentUser && fleet.account && (
            <Menu.Item>
              <ManageAccount
                currentUser={currentUser}
                fleet={fleet}
                account_fleets={account_fleets}
                handleUserAccount={this.handleUserAccount}
              />
            </Menu.Item>
          )}

          <Dropdown
            className={`circle ${isFleetAdvise() ? "circle-text-font" : ""}`}
            item
            text={<span style={{ padding: "10px 0" }}>{shortened}</span>}
            icon={null}
          >
            <Dropdown.Menu>
              <Dropdown.Header>
                <Header>{currentUserName}</Header>
              </Dropdown.Header>
              <Responsive maxWidth={990}>
                {isFleetcor() && !isFMC && currentUser && fleet.account && (
                  <ManageAccount
                    currentUser={currentUser}
                    fleet={fleet}
                    account_fleets={account_fleets}
                    handleUserAccount={this.handleUserAccount}
                  />
                )}
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.dashboard()}>Dashboard</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.vehicleIndex()}>Vehicles</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.servicesIndex()}>Services</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.shopIndex()}>Shops</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.settingsIndex()}>Settings</Link>
                </Dropdown.Item>
                {isFMC && (
                  <Dropdown.Item onClick={this.clickChild}>
                    <Link to={navigationPaths.fmcDashboard()}>FMC Dashboard</Link>
                  </Dropdown.Item>
                )}
              </Responsive>
              <Dropdown.Item onClick={this.clickChild}>
                <Link to={navigationPaths.userPreferences()}>{t("userPreferencesLabel")}</Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={this.clickChild}>
                <Link to={navigationPaths.signOut()}>{t("signOutLabel")}</Link>
              </Dropdown.Item>
              {fleet.fleet_links && fleet.fleet_links.length > 0 && <Dropdown.Divider />}
              <HeaderLinks fleetLinks={fleet.fleet_links} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    )
  }
} // class SiteHeader

const mapStateToProps = (state) => {
  const user = state.application.userSessionData || (state.users && state.users.currentUser)
  const isFMC = (user && user.isFMC) || isFMCUser(user)
  const MembershipUrl = window.location.pathname.match(navigationPaths.membershipCard())
  const isPathFMC =
    isFmcPath() || (state.application.mode === FMC && MembershipUrl && MembershipUrl.length > 0)
  return {
    currentUser: user,
    fleet: state.application.fleetSessionData || state.fleets.fleet,
    isFleetMode: state.application.mode === FLEET && !isFmcPath(),
    isFMCMode: state.application.mode === FMC && isFMC && isPathFMC,
    isFMC:
      (user && user.isFMC) ||
      (state.users &&
        state.users.currentUser &&
        state.users.currentUser.roles &&
        state.users.currentUser.roles.find(
          (role) => role === FMC || role === FMC_FLEET_ADMIN || role === FMC_FLEET_MANAGER
        )),
    vehicle: state.vehicles.vehicle,
    account_fleets: state.users.account_fleets,
    language:
      state.users.userSelectedLanguage ||
      (user && user.language) ||
      (state.users.currentUser && state.users.currentUser.language)
  }
}

export default connect(mapStateToProps)(withRouter(withTranslation("siteHeader")(SiteHeader)))
